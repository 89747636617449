* {
  background-color: #0b0b0b;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
  max-width: 100%;
  max-height: 100%;
}